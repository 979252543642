import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import IndexLink from './partials/IndexLink';
import { browserHistory } from '../helpers/components';
import classnames from 'classnames';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import _isEmpty from 'lodash/isEmpty';

import {
  setLoginPending,
  clearLoginPending,
  initKeycloak,
  isLoginPending,
  KEYCLOAK_STATUS
} from '../utils/keycloak';

import { IntlProvider, FormattedMessage } from 'react-intl';
import * as BookingActions from '../actions/booking';
import * as CreditCardActions from '../actions/creditCard';
import * as UserActions from '../actions/user';
import * as PopupActions from '../actions/popup';
import * as i18nActions from '../actions/i18nActions';
import * as BrandActions from '../actions/brand';
import * as KeycloakActions from '../actions/keycloak';
import * as ImpersonateActions from '../actions/impersonate';

import memberIcon from './../assets/images/member-placeholder.svg';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import SendActivationEmailForm from './forms/SendActivationEmailForm';

import {
  formatDataForSearchBookingsApiCall,
  setMomentTimeForRequest,
  getObjectFromHash,
  getObjectFromQuery
} from '../helpers';

import Popup from './popup/Popup';
import LoginPopup from './popup/LoginPopup';
import BookingRecapPopup from './popup/BookingRecapPopup';
import CreditCardPopup from './popup/CreditCardPopup';
import BookingCancelPopup from './popup/BookingCancelPopup';
import IntlGlobalProvider from '../utils/IntlGlobalProvider';

import {
  gtmPushBasicSearchData,
  getMessageOptions,
  isDevEnv,
  fallbackFunc,
  isPasswordUpdatePage,
  getAppObj,
  safe,
  isHomePage,
  isBookingPage,
  getDefaultLocale,
  getNavigatorLanguage,
  getMessage as t,
  navigateToCreationPage,
  sessionStorageSetJson
} from '../utils/utils';

import SearchBookingsHomepageForm from './forms/SearchBookingsHomepageForm';
import GenericPopup from './popup/GenericPopup';
import BookingSuccessPopup from './popup/BookingSuccessPopup';
import Footer from './partials/Footer';
import FaqPopup from './popup/FaqPopup';
import { getFaqLink } from '../helpers/components';

import {
  searchValuesSelector,
  userCompanyIdSelector,
  keycloakConfigSelector,
  isIncompleteUserSelector,
  userIdSelector,
  currentThemeSelector,
  isPrimaryColorDarkSelector,
  isSecondaryColorDarkSelector,
  memberInKeycloakSelector,
  userImpersonatedSelector,
  showAppLoaderSelector,
  brandKeycloakSelector,
  messagesSelector,
  isAppliedUserSelector
} from '../helpers/selectors';

import HowItWorks from './HowItWorks';
import { pt_BR_map } from '../actions/i18nActions/helpers';
import { keysMemberStatus } from '../constants/translations';
import BookingEditPopup from './popup/BookingEditPopup';
import config from '../constants/config';

import Flash from '../utils/flashMessage/selector';
import FlashMessage from '../utils/flashMessage/lib';
import { LEGACY_SUBSCRIBE, STORAGE_KEY } from '../constants/generic';
import PaymentErrorPopup from './popup/PaymentErrorPopup';
import { getErrorStatus } from '../helpers/formatters';
import { addActionError, addErrorMsg, addInfoMsg } from '../utils/flashMessage/creator';
import memoizeOne from 'memoize-one';
import DeepLinkMessage from './popup/DeepUrlMessage';
import AnonymizeConfirmPopup from './popup/AnonymizeConfirmPopup';
import { getErrorMsg } from '../helpers/errors';
import { book, validateAccount } from '../constants/routes';
import {
  clearAuthTokens,
  clearSsoTokens,
  getAppAuthTokens,
  getAppSsoTokens,
  restoreAuthTokens,
  restoreSsoTokens,
  saveAuthTokens,
  saveSsoTokens
} from '../utils/tokens';

const footerHideLocations = [`/${book}`];

const hashRoutes = {
  'reset-password': true,
  [validateAccount]: true
};

function getSizeProps() {
  return {
    isMobile: window.innerWidth < 768,
    isTablet: window.innerWidth < 1550
  };
}

function loadGtmScript(gtmId, onLoad) {
  const [w, d, s, l] = [window, document, 'script', 'dataLayer'];

  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });

  // noinspection EqualityComparisonWithCoercionJS
  const f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    // eslint-disable-next-line eqeqeq
    dl = l != 'dataLayer' ? '&l=' + l : '';

  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dl;
  j.onload = onLoad;
  f.parentNode.insertBefore(j, f);
}

function addTagManagerToDom(gtmId) {
  loadGtmScript(gtmId, () => {
    getAppObj().gtmId = gtmId;
  });
}

function setFlashMessage() {
  getAppObj().Flash = Flash;
  getAppObj().FlashMessage = FlashMessage;
}

function getCssThemeConfig(brandThemeObj, important = false) {
  let css = '';
  if (_isEmpty(brandThemeObj)) return css;

  important = important ? ' !important' : '';

  for (let [key, value] of Object.entries(brandThemeObj)) {
    css += key + ':' + value + important + ';';
  }

  return css;
}

function getCssProps(btnThemed, defaultProps) {
  if (btnThemed === '') return 'background-color:' + defaultProps + ';';
  return btnThemed;
}

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMenu: false,
      isMobile: false,
      isTablet: false
    };

    restoreAuthTokens();
    restoreSsoTokens();
    setFlashMessage();

    this.setLocale();
    this.setCallbacks(props);
    this.getRemoteData(props);
    this.componentPropsUpdated(props, true);
  }

  componentDidMount() {
    this.addUnloadListener();
    this.addResizeListener();
    this.setTokenUpdates();
    this.onResize();
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  addUnloadListener() {
    window.addEventListener(
      'beforeunload',
      () => {
        const { user } = this.props;
        const { data: userData } = user;

        const tokens = getAppAuthTokens();
        const ssoTokens = getAppSsoTokens();

        if (tokens.token || tokens.refreshToken) {
          saveAuthTokens(tokens);
        } else if (ssoTokens.token || ssoTokens.refreshToken) {
          saveSsoTokens(ssoTokens);
        }
        if (userData?.id) {
          sessionStorageSetJson(STORAGE_KEY.USER, userData);
        }
      },
      { capture: true }
    );
  }

  addResizeListener() {
    window.addEventListener('resize', this.onResize, { capture: true });
  }

  removeResizeListener() {
    window.removeEventListener('resize', this.onResize, { capture: true });
  }

  setTokenUpdates() {
    setInterval(() => {
      const ssoTokens = getAppSsoTokens();
      const authTokens = getAppAuthTokens();

      if (ssoTokens.refreshToken) {
        safe(() =>
          getAppObj().keycloak.updateToken(86400).then(saveSsoTokens).catch(fallbackFunc)
        );
      } else if (authTokens.refreshToken) {
        this.props.updateTokenSilent(authTokens.refreshToken).catch(fallbackFunc);
      }
    }, 3600000);
  }

  showErrorMsg(data) {
    addErrorMsg(
      getErrorMsg({
        bundle: this.props.m,
        error: ((data.error || {}).response || {}).data
      })
    );
  }

  setCallbacks(props) {
    this.hideGenericPopupAction = () => props.popup_Generic_SetVisibility(false);

    this.closeRecapPopup = () => {
      this.props.popup_BookingRecap_SetVisibility(false);
      this.props.emptyVehicleData();
    };

    this.closeBookingCancelPopup = () => {
      this.props.popup_BookingCancel_SetVisibility(false);
      this.props.emptyBookingCancelId();
    };

    this.closeBookingSuccessPopup = () => {
      this.props.popup_BookingSuccess_SetVisibility(false);
      this.props.clearAddToCalendarData();
    };

    this.closeCreditCardPopup = () => {
      const { popup_CreditCard_SetVisibility } = this.props;
      popup_CreditCard_SetVisibility(false);
    };

    this.closeAnonymizeConfirmPopup = () => {
      const { popup_AnonymConfirm_SetVisibility } = this.props;
      popup_AnonymConfirm_SetVisibility(false);
    };

    this.handleAnonymizeConfirmPopup = () => {
      const { selfAnonymize } = this.props;

      this.closeAnonymizeConfirmPopup();
      selfAnonymize().then((data) => {
        if (data?.type === 'user/SELF_ANONYMIZE_SUCCESS') {
          addInfoMsg(t(this.props.m, 'registered.profile.anonymize.success'));
        } else {
          this.showErrorMsg(data);
        }
      });
    };

    this.handleGetCreditCard = () => {
      const { getCreditCardURL, userId, m } = this.props;

      if (userId) getCreditCardURL();
      else addErrorMsg(t(m, 'error.session.expired'));

      this.closeCreditCardPopup();
    };

    this.resetStateLoginModal = () => {
      this.props.popup_Login_SetVisibility(false);
      this.props.popupStep_Subscribe_SetVisibility(false);
    };

    this.closeFaqPopup = () => {
      this.props.popup_Faq_SetVisibility(false);
    };

    this.closeBookingEditPopup = () => {
      this.props.popup_BookingEdit_SetVisibility(false);
      this.props.clearBookingEditPopupData();
    };

    this.toggleMobileMenu = () => {
      this.setState({
        mobileMenu: !this.state.mobileMenu
      });
    };

    this.closeMobileMenu = () => {
      this.setState({
        mobileMenu: false
      });
    };

    this.openFaqPopup = () => {
      this.props.popup_Faq_SetVisibility(true);
      this.closeMobileMenu();
    };

    this.searchBookings = (data) => {
      const {
        search,
        brand: { branded },
        userId
      } = this.props;

      const companyId = _get(this.props.user, 'data.company.id');
      const searchFormValues = _get(this.props.form, 'searchBookings.values');

      const formattedData = formatDataForSearchBookingsApiCall(
        setMomentTimeForRequest(data),
        companyId,
        branded.contract
      );

      search(formattedData).then((data) => {
        if (data.type === 'booking/SEARCH_SUCCESS') {
          gtmPushBasicSearchData(data, searchFormValues, userId);
        }
      });
    };

    this.handleSendActivationEmail = (data) => {
      const { login } = data || {};
      if (login) this.props.sendActivationEmail(login);
    };

    this.closeTabletBurger = () => {
      if (window.innerWidth >= 1550) {
        if (document.getElementById('menu-btn')) {
          document.getElementById('menu-btn').checked = false;
        }
      }
    };

    this.onResize = () => {
      this.setState(getSizeProps());
      this.closeTabletBurger();
    };

    const handleHashRoutesMem = memoizeOne((hash) => {
      if (hash) {
        const split = hash.split('/');
        const route = split[1];

        if (hashRoutes[route]) {
          browserHistory.replace(hash.substring(1));
        }
      }
    });
    this.handleHashRoutes = () => {
      handleHashRoutesMem(this.props.location.hash);
    };

    const handleSubscribeRedirectMem = memoizeOne((hash) => {
      if (hash) {
        const split = hash.split('/');
        const page = split[1];
        const id = split[2];

        if (page === LEGACY_SUBSCRIBE && id) {
          navigateToCreationPage(id);
        }
      }
    });
    this.handleSubscribeRedirect = () => {
      handleSubscribeRedirectMem(this.props.location.hash);
    };

    const canShowFooterMem = memoizeOne((pathname) => {
      return !footerHideLocations.includes(pathname);
    });
    this.canShowFooter = () => canShowFooterMem(this.props.location.pathname);

    const getPageClassMem = memoizeOne((pathname) => {
      return pathname.replaceAll('/', '_');
    });
    this.getPageClass = () => getPageClassMem(this.props.location.pathname);

    this.openLoginPopup = () => this.props.popup_Login_SetVisibility(true);

    this.popupQueryCallbacks = {
      'reset-password-popup': () => this.props.popup_ResetPassword_SetVisibility(true),
      'account-activation-popup': () =>
        this.props.popup_AccountValidation_SetVisibility(true),
      'activation-email-sent-popup': () =>
        this.props.popup_ActivationEmailSent_SetVisibility(true),
      'login-popup': this.openLoginPopup
    };
  }

  handleIncompleteUser() {
    const p = this.props;

    if ((p.isIncompleteUser || p.isAppliedUser) && !getAppObj().incompleteChecked) {
      getAppObj().incompleteChecked = true;
      p.handleIncompleteUserRedirect();
    }
  }

  canRedirectToForceUpdate() {
    const props = this.props;
    return props.userId && !props.memberInKeycloak && !props.userImpersonated;
  }

  handleForceUpdatePassword() {
    if (this.canRedirectToForceUpdate()) {
      if (!isPasswordUpdatePage()) {
        setTimeout(() => {
          if (this.canRedirectToForceUpdate()) {
            browserHistory.push('/password-update');
          }
        }, 500);
      }
    }
  }

  verifyExternalLogin({ superCompanyId }) {
    saveSsoTokens();

    this.props.verifyUser().then((resp) => {
      const { useOnlySsoForLogin } = this.props.brandKeycloak || {};
      this.ssoHideLoader();

      if (resp.type === 'keycloak/VERIFY_USER_SUCCESS') {
        this.props.handleLoginSuccess(resp);
      } else {
        this.props.handleVerifyUserFail(resp);

        if (getErrorStatus(resp) === 404 && useOnlySsoForLogin) {
          browserHistory.push('/select-company');
          return;
        }

        if (getErrorStatus(resp) === 404 && superCompanyId) {
          navigateToCreationPage(superCompanyId);
          return;
        }
        addErrorMsg(t(this.props.m, 'sso.register.no.company'));
      }
    });
  }

  updateTokenFromStorage() {
    const isVerifySucess = (type) => type === 'keycloak/VERIFY_USER_SUCCESS';

    getAppObj().keycloak.refreshToken = getAppSsoTokens().refreshToken;
    getAppObj()
      .keycloak.updateToken()
      .then(() => {
        saveSsoTokens();
        this.props.verifyUser().then((resp) => {
          if (!isVerifySucess(resp.type)) {
            clearSsoTokens();
          }
          this.ssoHideLoader();
        });
      })
      .catch(() => {
        clearSsoTokens();
        this.ssoHideLoader();
      });
  }

  handleKeycloak(props) {
    if (getAppObj().keycloakInit) return;
    getAppObj().keycloakInit = true;

    const { keycloakConfig } = props;
    const { initSettings } = keycloakConfig || {};
    const ssoTokens = getAppSsoTokens();

    if (!initSettings) return this.ssoDelayLoader();

    initKeycloak(initSettings, (status) => {
      if (status === KEYCLOAK_STATUS.AUTH) {
        this.verifyExternalLogin(keycloakConfig);
      } else if (!isLoginPending() && ssoTokens.refreshToken) {
        this.updateTokenFromStorage(props);
      } else {
        this.ssoDelayLoader();
      }
    });
  }

  ssoHideLoader(clear = true) {
    this.props.setAppLoaderState(false);
    if (clear) clearLoginPending();
  }

  setLoginStatus(status) {
    this.weDidLogin = status;
  }

  weCanLogin() {
    return !this.weDidLogin;
  }

  handleOurTokens(props) {
    const { impersonateToken } = getObjectFromHash() || {};

    if (impersonateToken) {
      this.handleImpersonateToken(impersonateToken);
      this.setLoginStatus(true);
    } else if (this.weCanLogin()) {
      this.handleLoginUser(props);
      this.setLoginStatus(true);
    }
  }

  handleTokens() {
    const props = this.props;

    if (props.brand.configFetched) {
      this.handleOurTokens(props);
      this.handleKeycloak(props);
    }
  }

  handleImpersonateToken(impersonateToken) {
    if (!this.fetchingToken) {
      this.fetchingToken = true;
      setLoginPending();

      this.props
        .loginUserWithToken(impersonateToken, { useLegacyRefresh: true })
        .then((data) => {
          if (data.type === 'user/REFRESH_SUCCESS') {
            this.props.setImpersonateUserStatus(true);
            this.props.handleLoginSuccess(data);
          } else {
            addActionError(data);
          }

          clearLoginPending();
          this.fetchingToken = false;
          browserHistory.push('/');
        });
    }
  }

  handleLoginUser(props) {
    const { data: userHasData } = props.user;
    const tokens = getAppAuthTokens();

    if (tokens.refreshToken && !userHasData) {
      this.props.loginUserWithToken(tokens.refreshToken).then((data) => {
        if (data?.type === 'user/REFRESH_SUCCESS') {
          this.props.handleLoginSuccess(data);
        } else {
          clearAuthTokens();
        }
      });
    }
  }

  getRemoteData(props) {
    props.getAwsBrand();
  }

  setLocale() {
    this.props.setLanguage(getDefaultLocale());
  }

  renderActivationPopup() {
    if (this.props.user.activationSuccess) {
      return (
        <div>
          <p className="title">
            <FormattedMessage id="label.congratulations" />
            <br />
            <FormattedMessage id="label.account_activation_title" />
          </p>
          <DeepLinkMessage />
          <p className="description">
            <FormattedMessage id="label.account_activation_description" />
          </p>
        </div>
      );
    } else if (this.props.user.alreadyActivated) {
      return (
        <p className="single">
          <FormattedMessage id="label.account.already.active" />
        </p>
      );
    } else if (this.props.user.activationExpired) {
      return (
        <SendActivationEmailForm
          onSubmit={this.handleSendActivationEmail}
          i18n={this.props.i18n}
          fetching={this.props.user.sendEmailFetching}
        />
      );
    } else {
      return (
        <p className="error">
          <FormattedMessage id="label.generic.error" />
        </p>
      );
    }
  }

  renderActivationEmailSentPopup() {
    if (this.props.user.sendEmailSuccess)
      return (
        <p className="single">
          <FormattedMessage id="label.activation.link.sent.success" />
        </p>
      );
    else
      return (
        <p className="error">
          <FormattedMessage id="label.generic.error" />
        </p>
      );
  }

  sendActivationEmailListener() {
    const { sendEmailFetching } = this.props.user || {};

    if (sendEmailFetching) this.activationEmailSent = true;
    if (!sendEmailFetching && this.activationEmailSent) {
      this.activationEmailSent = false;
      this.props.popup_AccountValidation_SetVisibility(false);

      browserHistory.push({
        pathname: '/',
        query: { modal: 'activation-email-sent-popup' }
      });
    }
  }

  locationUpdated(pp, init) {
    const { location: prev } = pp;
    const { location: next } = this.props;
    return init || prev !== next;
  }

  handlePopupQueries(pp, init) {
    if (this.locationUpdated(pp, init)) {
      const { search } = this.props.location || {};
      const { modal } = getObjectFromQuery(search) || {};
      const callback = this.popupQueryCallbacks[modal];
      if (callback) callback();
    }
  }

  componentPropsUpdated(pp, init) {
    this.loadTagManager();
    this.handleHashRoutes();
    this.handleSubscribeRedirect();
    this.sendActivationEmailListener();
    this.handleTokens();
    this.handleIncompleteUser();
    this.handleForceUpdatePassword();
    this.handlePopupQueries(pp, init);
    this.handleBrandLanguage(pp, init);
  }

  componentDidUpdate(pp) {
    this.handleMobileModalClasses();
    this.componentPropsUpdated(pp);
  }

  isSearchBookingsLayout() {
    return this.props.location.pathname === '/search-bookings';
  }

  brandChanged(pp, init) {
    const { pathname: nv } = this.props.brand.branded;
    const { pathname: pv } = pp.brand.branded;
    return init || nv !== pv;
  }

  handleBrandLanguage(pp, init) {
    const np = this.props;

    if (np.brand.configFetched) {
      const { language: brandLanguages } = np.brand.branded || {};

      if (this.brandChanged(pp, init)) {
        if (brandLanguages) {
          const { locale } = np.i18n;

          if (!brandLanguages.includes(locale)) {
            const browserLanguage = getNavigatorLanguage();

            if (brandLanguages.includes(browserLanguage)) {
              np.setLanguage(browserLanguage);
            } else {
              np.setLanguage('en');
            }
          }
        }
      }
    }
  }

  handleMobileModalClasses() {
    if (this.state.mobileMenu) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  loadTagManager() {
    const { branded, configFetched } = this.props.brand;
    const { gtmId } = branded || {};

    if (!this.gtmLoaded && configFetched) {
      this.gtmLoaded = true;

      if (gtmId && !isDevEnv()) {
        addTagManagerToDom(gtmId);
      }
    }
  }

  ssoDelayLoader() {
    setTimeout(() => this.ssoHideLoader(false), config.appLoaderMinTime);
    clearLoginPending();
  }

  renderLogo() {
    const {
      brand: { branded },
      theme
    } = this.props;
    const { logo } = theme || {};

    if (theme) {
      return <img src={logo} alt={branded.productName} />;
    }
  }

  renderLogoBgw() {
    const {
      theme,
      brand: { branded }
    } = this.props;
    const { logoMobile } = theme || {};

    if (theme) {
      return <img className="brand-logo" src={logoMobile} alt={branded.productName} />;
    }
  }

  isHeaderBurger() {
    return footerHideLocations.includes(this.props.location.pathname);
  }

  renderBackgroundProps() {
    const { theme } = this.props;
    const { background } = theme || {};
    const color = 'rgba(0, 0, 0, 0.45)';

    if (theme && isHomePage()) {
      return {
        background: `linear-gradient(${color}, ${color}), url(${background}) no-repeat 30% 40% /cover`
      };
    }
  }

  renderDropdownButton() {
    const { user, theme, logout } = this.props;
    const { profile } = theme.icons || {};
    const budy = user.data.firstName + ' ' + user.data.lastName;

    return (
      <div className="container-avatar">
        <DropdownButton title={budy} id="dropdown-myaccount" className="account-menu">
          <Dropdown.Item
            eventKey="1"
            onClick={() => browserHistory.push('/profile/personal-info')}
          >
            <span className="sub-menu">
              <FormattedMessage id="label.my_account" />
            </span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="2" onClick={logout}>
            <span className="sub-menu">
              <FormattedMessage id="label.logout" />
            </span>
          </Dropdown.Item>
        </DropdownButton>
        <img src={profile ? profile : memberIcon} alt="" />
      </div>
    );
  }

  setMobileStyle() {
    if (this.state.isMobile) {
      const { theme, isPrimaryColorDarkContrast } = this.props;
      const textContrasted = isPrimaryColorDarkContrast ? '#FFF' : '#000';
      const { primary } = theme.colors || {};

      return (
        <style jsx global>
          {`
            .new-design-radio-input:checked + .new-design-radio-label {
              background-color: ${primary} !important;
              color: ${textContrasted} !important;
            }
          `}
        </style>
      );
    }
  }

  setStyle() {
    const { brand, theme, isPrimaryColorDarkContrast, isSecondaryColorDarkContrast } =
      this.props;

    const { branded } = brand || {};
    const { primary: backgroundColor, secondary: textColor } = theme.colors || {};
    const { primary: primaryBtn, secondary: secondaryBtn } = theme.buttons || {};
    const { bgcolor, radius } = theme.datetimePicker || {};
    const { hr } = theme || {};
    const datePickerBG = bgcolor || undefined;
    const pickerRadiusDefault = '40px';
    const headerHeightDefault = '60px';
    const colorPicker = '#D9D9D9';
    const textContrasted = isPrimaryColorDarkContrast ? '#FFF' : '#000';
    const textContrasted2 = isSecondaryColorDarkContrast ? textColor : '#000';
    const datePickerRad = String(radius) || undefined;
    const radiusValue = !!datePickerRad ? datePickerRad : pickerRadiusDefault;
    const bgcolorValue = !!datePickerBG ? datePickerBG : backgroundColor;
    const headerHeight = theme.headerHeight || headerHeightDefault;

    const {
      corporate: corpoIconUrl,
      private: privateIconUrl,
      vr: vrIconUrl
    } = branded.servicesIcons || {};

    const primaryBtnThemed = getCssThemeConfig(primaryBtn, true);
    const primaryBtnProps = getCssProps(primaryBtnThemed, backgroundColor);
    const secondaryBtnThemed = getCssThemeConfig(secondaryBtn, true);
    const secondaryBtnProps = getCssProps(secondaryBtnThemed, backgroundColor);
    const faqLastAccordion = '.faq .accordion-level2 .accordion-item .accordion-header';

    if (theme) {
      return (
        <style jsx global>
          {`
            :root {
              --height: ${headerHeight};
            }
            .primary {
              color: ${backgroundColor} !important;
            }

            .secondary {
              color: ${textContrasted2} !important;
            }

            .primary-border {
              border-color: ${backgroundColor} !important;
            }

            .primary-bg {
              ${primaryBtnProps};
              transition: all ease 0.3s;
            }
            .secondary-bg,
            .scope-submit-button.secondary-bg {
              ${secondaryBtnProps};
              transition: all ease 0.3s;
            }

            // apply-only-one-time classes -start-

            .primary-btn {
              ${primaryBtnProps}
            }

            .secondary-btn,
            .scope-submit-button.secondary-btn {
              ${secondaryBtnProps}
            }

            .switch-profile-page .scope-pseudo-btn {
              ${secondaryBtnProps}
            }

            // apply-only-one-time -end-

            .new-design-address-container input:focus,
            .new-design-field:focus {
              border-color: ${backgroundColor};
            }

            .nav-container #menu-mobile ul li .link.active {
              background-color: ${backgroundColor};
              color: ${textContrasted};
            }

            .border-primary-hover > button:hover {
              border-color: ${backgroundColor} !important;
            }

            .border-primary-active > button {
              border-color: ${backgroundColor} !important;
            }

            .subscription .offers .corporate {
              ${corpoIconUrl && `background-image: url('${corpoIconUrl}') !important;`};
            }
            .subscription .offers .private {
              ${privateIconUrl &&
              `background-image: url('${privateIconUrl}') !important;`};
            }
            .subscription .offers .vr {
              ${vrIconUrl && `background-image: url('${vrIconUrl}') !important;`};
            }

            #header {
              ${headerHeight !== '' ? 'height:' + headerHeight : ''}
            }

            .picker__day--infocus.picker__day--highlighted.picker__day--selected {
              background: ${bgcolorValue} !important;
              border-radius: ${radiusValue}px !important;
              color: white !important;
            }

            .picker__day--selected {
              background: ${bgcolorValue} !important;
              border-radius: ${radiusValue}px !important;
            }

            .picker__weekday {
              ${isPrimaryColorDarkContrast
                ? `color: ${bgcolorValue} !important;`
                : `color: #000 !important;`}
            }
            .picker__day--highlighted {
              border-color: ${bgcolorValue} !important;
            }

            .date-picker .picker__day--today.picker__day--highlighted {
              border-radius: ${pickerRadiusDefault} !important;
              color: #000 !important;
              border-color: #d9d9d9 !important;
              background: #d9d9d9 !important;
            }

            .picker__day--infocus:hover {
              border-radius: ${radiusValue} !important;
            }

            .date-picker .picker__day--disabled,
            .date-picker .picker__day--outfocus:hover {
              background: white !important;
              color: #f6f6f6 !important;
              border-color: white;
            }

            .date-picker .picker__day--outfocus:not(.picker__day--disabled):hover,
            .date-picker .picker__day--infocus:not(.picker__day--disabled):hover,
            .date-picker .picker__day--today.picker__day--highlighted:hover {
              background: #d9d9d9 !important;
              color: #000 !important;
              border-color: #d9d9d9 !important;
              border-radius: ${radiusValue} !important;
            }

            .picker__day--today.picker__day--highlighted.picker__day--selected {
              background: ${bgcolorValue} !important;
              border-radius: ${radiusValue}px !important;
              color: white !important;
            }

            .picker--focused .picker__list-item--selected,
            .picker--focused .picker__list-item--highlighted,
            .picker__list-item--selected,
            .picker__list-item--selected:hover,
            .picker__list-item--highlighted,
            .picker__list-item--highlighted:hover {
              background: ${bgcolorValue} !important;
              border-color: ${bgcolorValue} !important;
            }

            .filter-item .vehicle-type .radio-icon input:checked ~ label {
              background-color: ${backgroundColor} !important;
              color: ${textContrasted} !important;
            }

            input:checked + label .radio-btn,
            input:checked ~ label {
              border-color: ${backgroundColor} !important;
            }

            input:checked + label .radio-btn::after {
              background-color: ${backgroundColor} !important;
              color: ${textContrasted};
            }

            input[type='checkbox'] + label .chk-btn {
              border-color: ${backgroundColor} !important;
            }

            .FiltersMobile-popup input[type='checkbox']:checked + label .chk-btn:after,
            .FiltersMobile-popup input[type='checkbox']:checked + label .chk-btn,
            input[type='checkbox']:checked + label .chk-btn:after,
            input[type='checkbox']:checked + label .chk-btn {
              background-color: ${backgroundColor} !important;
              color: ${textContrasted};
            }

            .date-picker.picker--active,
            .time-picker.picker--active {
              border: 1px solid ${colorPicker} !important;
              border-radius: 4px;
            }

            .date-picker.picker--active .picker__holder {
              border: unset;
              margin-top: 5px;
              box-shadow: 0 0 3px rgba(0, 0, 0, 0.6) !important;
            }

            .subscribe .react-svg.back-arrow svg path,
            .scope-date-range .react-svg,
            .scope-content-header-button-return .react-svg {
              fill: ${backgroundColor};
            }

            .field-wrapper .input-wrapper .valid-icon,
            .field-wrapper .input-wrapper .valid-icon::before {
              color: ${backgroundColor};
            }

            .location-input .mapbox-complete .location-spinner {
              border-top-color: ${backgroundColor};
            }

            .search-form .mapbox-complete .mc-input:focus,
            .search-form .location input:focus,
            .search-form .location input[aria-expanded='true'] {
              border: 1px solid ${colorPicker} !important;
            }

            .search-form .mapbox-complete .mc-dropdown-menu {
              border: 1px solid ${colorPicker};
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            }

            ${faqLastAccordion} button:hover,
            ${faqLastAccordion} button[aria-expanded='true'] {
              background: ${backgroundColor};
              color: ${textColor};
            }

            .booking-history .booking-history-tabs li button.active {
              border-color: ${backgroundColor};
              background: ${backgroundColor};
              color: ${textContrasted};
            }

            .booking-history-details-start-date-time {
              border-bottom: 2px solid ${backgroundColor};
            }

            .booking-history-details-content.sub-class-mobile-msg
              .booking-history-details-content-footer {
              background-color: ${backgroundColor};
              color: ${textColor};
            }

            .new-design-radio-input:checked
              + .new-design-radio-label
              .new-design-radio-btn {
              border-color: ${backgroundColor} !important;
            }

            .new-design-radio-input:checked
              + .new-design-radio-label
              .new-design-radio-btn:after {
              background-color: ${backgroundColor} !important;
            }

            .profile-page .scope-menu-body-link-container a:hover {
              border-color: ${backgroundColor};
            }

            .booking-success-popup .react-add-to-calendar__dropdown li:hover,
            .booking-success-popup .react-add-to-calendar__dropdown li:hover a {
              background-color: ${backgroundColor};
              color: ${textContrasted};
            }

            .spinner {
              border-top-color: ${backgroundColor};
            }

            .register-page > .scope-page-header .scope-step.sub-class-active {
              background-color: ${backgroundColor};
              color: ${textColor};
            }

            .footer .container-language .dropdown.show .btn,
            .footer .container-language .dropup.show .btn {
              background-color: ${backgroundColor};
              color: ${textContrasted};
              fill: ${textContrasted};
            }

            .agency-circle svg circle,
            .search-filters-layout .search-detail div.svg-arrow {
              fill: ${backgroundColor};
            }

            .scope-credit-card-container {
              border: 2px solid ${backgroundColor};
            }

            .btn-book {
              color: ${textContrasted};
            }

            .agency-circle .figure {
              color: ${textContrasted};
            }

            .google-map-popup .agency-name {
              background-color: ${backgroundColor} !important;
              color: ${textContrasted} !important;
            }

            .bookings-map .map-info-wrap .arrow-down {
              border-color: ${backgroundColor} transparent transparent;
            }

            .ccpane .ccbackg,
            .ccb-copy,
            .ccb-close-label {
              background-color: ${backgroundColor} !important;
              color: ${textColor} !important;
            }

            .scope-button-cancel {
              background-color: ${backgroundColor};
              color: ${textContrasted};
            }

            .business-tabs div:first-child::before {
              background-color: ${textColor};
            }

            .car-popup .wrapper li:before,
            .car-popup .wrapper li:after {
              background-color: ${textColor};
            }

            .scope-booking-recap-hr,
            .hr-dashed,
            .login hr {
              margin: 20px 0;
              ${getCssThemeConfig(hr)}
            }
          `}
        </style>
      );
    }
  }

  searchBookingsHomeForm() {
    const {
      user,
      brand: { branded },
      user: { companyIdError },
      searchValues
    } = this.props;

    const isRestrictedAccess = branded.restrictedAccess;

    if ((!isRestrictedAccess || user.data) && isBookingPage()) {
      return (
        <div id="search-form-result">
          <SearchBookingsHomepageForm
            onSubmit={this.searchBookings}
            i18n={this.props.i18n}
            contract={branded.contract}
            user={user.data}
            initialValues={searchValues}
            updateOnChange
            requestError={companyIdError}
            classname="search-header"
            noExpandingClick={false}
          />
        </div>
      );
    }
  }

  userHeader() {
    const {
      brand: {
        branded: {
          contract: {
            interfaceConfig: { replacementVehicleDisplay: isRV }
          },
          mapCountries
        }
      },
      userCompanyId
    } = this.props;

    return (
      <ul id="links" className="set-height">
        {!isRV && (
          <li>
            <IndexLink to="/book" activeClassName="active">
              <FormattedMessage id="generic.book" />
            </IndexLink>
          </li>
        )}
        <li>
          <IndexLink to="/my-bookings" activeClassName="active">
            <FormattedMessage id="label.my_reservations" />
          </IndexLink>
        </li>
        <li>
          {getFaqLink({
            label: <FormattedMessage id="menu.faq" />,
            linkProps: { activeClassName: 'active' },
            userCompanyId,
            mapCountries,
            action: this.openFaqPopup
          })}
        </li>
        <li className="dropdown-li">{this.renderDropdownButton()}</li>
        <li className="tablet-profile-link">
          <IndexLink to="/profile/personal-info" activeClassName="active">
            <FormattedMessage id="label.my_account" />
          </IndexLink>
        </li>
        <li className="tablet-profile-link">
          <a onClick={this.props.logout} role="button" style={{ cursor: 'pointer' }}>
            <FormattedMessage id="label.logout" />
          </a>
        </li>
      </ul>
    );
  }

  makeBookingUrl() {
    const { brand } = this.props;
    const { branded } = brand;
    const { restrictedAccess } = branded || {};

    if (!restrictedAccess) {
      return (
        <li>
          <IndexLink to="/book" activeClassName="active" onClick={this.closeMobileMenu}>
            <FormattedMessage id="generic.book" />
          </IndexLink>
        </li>
      );
    }
  }

  noUserHeader() {
    const { isTablet } = this.state;

    const {
      brand: {
        branded: { mapCountries }
      },
      userCompanyId
    } = this.props;

    const clname =
      this.isHeaderBurger() && isTablet ? 'login' : 'login btn secondary-btn';

    return (
      <ul id="links">
        {this.makeBookingUrl()}
        <li>
          {getFaqLink({
            label: <FormattedMessage id="menu.faq" />,
            linkProps: { activeClassName: 'active' },
            userCompanyId,
            mapCountries,
            action: this.openFaqPopup
          })}
        </li>
        <li>
          <span className={clname} onClick={this.openLoginPopup}>
            <FormattedMessage id="label.login" />
          </span>
        </li>
      </ul>
    );
  }

  mobileHeader() {
    const burgerIconClasses = classnames('burger-icon', {
      open: this.state.mobileMenu
    });

    if (!this.isSearchBookingsLayout())
      return (
        <header id="header-mobile">
          <div className="container pos-rel h-100 text-center">
            <div className={burgerIconClasses} onClick={this.toggleMobileMenu}>
              <span />
              <span />
              <span />
            </div>
            <IndexLink to="/">{this.renderLogoBgw()}</IndexLink>
          </div>
        </header>
      );
  }

  getHeaderBanner() {
    const { brand } = this.props;
    const { branded } = brand || {};

    if (branded.preHeaderRessource && isHomePage()) {
      return (
        <div className="banner-header">
          <span
            dangerouslySetInnerHTML={{
              __html: branded.preHeaderRessource[this.getIntlLocale()]
            }}
          />
        </div>
      );
    }
  }

  getHeader() {
    const { user } = this.props;
    const homePage = isHomePage();

    const homePageClass = classnames({
      landing: homePage,
      'booking-page': this.isHeaderBurger()
    });

    const headerClassname = classnames('hidden-xs upper-section-wrapper', {
      landing: homePage
    });

    if (!this.isSearchBookingsLayout())
      return (
        <div className={headerClassname}>
          <header id="header">
            <nav id="user-menu" className={homePageClass}>
              <IndexLink to="/">
                {homePage ? this.renderLogo() : this.renderLogoBgw()}
              </IndexLink>
              {this.searchBookingsHomeForm()}
              <input className="menu-btn" type="checkbox" id="menu-btn" />
              <label className="menu-icon" htmlFor="menu-btn">
                <span className="navicon" />
              </label>
              {user.data ? this.userHeader() : this.noUserHeader()}
            </nav>
          </header>
        </div>
      );
  }

  getFooter() {
    if (this.canShowFooter() && !this.isSearchBookingsLayout()) return <Footer />;
  }

  mobileMakeBookingUrl() {
    const { brand } = this.props;
    const { branded } = brand;
    const { restrictedAccess } = branded || {};

    if (!restrictedAccess) {
      return (
        <li>
          <IndexLink
            to="/book"
            className="link"
            activeClassName="active"
            onClick={this.closeMobileMenu}
          >
            <FormattedMessage id="generic.book" />
          </IndexLink>
        </li>
      );
    }
  }

  mobileNoUserMenu() {
    const {
      brand: {
        branded: { mapCountries }
      },
      userCompanyId
    } = this.props;

    return (
      <ul className="w-100">
        <li className="logo">
          <IndexLink to="/" onClick={this.closeMobileMenu}>
            {this.renderLogoBgw()}
          </IndexLink>
        </li>
        {this.mobileMakeBookingUrl()}
        <li>
          {getFaqLink({
            label: <FormattedMessage id="menu.faq" />,
            linkProps: {
              activeClassName: 'active',
              className: 'link',
              onClick: this.closeMobileMenu
            },
            userCompanyId,
            mapCountries,
            action: this.openFaqPopup
          })}
        </li>
        <li onClick={this.closeMobileMenu}>
          <span className="link" onClick={this.openLoginPopup}>
            <FormattedMessage id="label.login" />
          </span>
        </li>
      </ul>
    );
  }

  getMobileUserStatus() {
    const { i18n } = this.props;
    const { messages } = i18n;
    const userData = this.props.user.data;
    const { status, suspended } = userData || {};
    const userStatus = suspended ? 'profile.suspended' : status;

    return (
      <div className={classnames('status', status, { suspended: suspended })}>
        {getMessageOptions(messages, keysMemberStatus, userStatus)}
      </div>
    );
  }

  mobileUserMenu() {
    const {
      user,
      brand: {
        branded: {
          contract: {
            interfaceConfig: { replacementVehicleDisplay: isRV }
          },
          mapCountries
        }
      },
      theme,
      userCompanyId
    } = this.props;
    const { profile } = theme.icons || {};

    return (
      <ul className="w-100">
        <li>
          <span className="member-known">
            <img src={profile ? profile : memberIcon} className="member-pic" alt="" />
            {user.data.firstName} {user.data.lastName}
            {this.getMobileUserStatus()}
          </span>
        </li>
        {!isRV && (
          <li>
            <IndexLink
              to="/book"
              className="link"
              activeClassName="active"
              onClick={this.closeMobileMenu}
            >
              <FormattedMessage id="generic.book" />
            </IndexLink>
          </li>
        )}
        <li>
          <IndexLink
            to="/my-bookings"
            className="link"
            activeClassName="active"
            onClick={this.closeMobileMenu}
          >
            <FormattedMessage id="label.my_reservations" />
          </IndexLink>
        </li>
        <li>
          <IndexLink
            className="link"
            to="/profile"
            activeClassName="active"
            onClick={this.closeMobileMenu}
          >
            <FormattedMessage id="label.my_account" />
          </IndexLink>
        </li>
        <li>
          {getFaqLink({
            label: <FormattedMessage id="menu.faq" />,
            linkProps: {
              activeClassName: 'active',
              className: 'link',
              onClick: this.closeMobileMenu
            },
            userCompanyId,
            mapCountries,
            action: this.openFaqPopup
          })}
        </li>
        <li onClick={this.closeMobileMenu}>
          <span className="link logout" onClick={_partial(this.props.logout)}>
            <FormattedMessage id="label.logout" />
          </span>
        </li>
      </ul>
    );
  }

  getMobileMenu() {
    const { user } = this.props;

    const mobileNavClasses = classnames('nav-container', {
      'mobile-nav-opened': this.state.mobileMenu
    });

    return (
      <div className="visible-xs">
        <div className={mobileNavClasses}>
          <div className="overlay" onClick={this.toggleMobileMenu} />
          <nav id="menu-mobile">
            {user.data ? this.mobileUserMenu() : this.mobileNoUserMenu()}
          </nav>
        </div>
        {this.mobileHeader()}
      </div>
    );
  }

  loginPopupIsLeveled() {
    return (
      this.props.popup.step_Subscribe_IsVisible ||
      this.props.popup.step_ForgotPassword_IsVisible ||
      this.props.popup.step_ExternalLogin_IsVisible
    );
  }

  loginPopup() {
    if (this.props.popup.type_Login_IsVisible) {
      return (
        <Popup
          isError
          isOpen={this.props.popup.type_Login_IsVisible}
          onCloseRequested={this.resetStateLoginModal}
          id={classnames('login-popup', {
            leveled: this.loginPopupIsLeveled(),
            addAccountStep: this.props.popup.step_AddAccount_IsVisible
          })}
        >
          <LoginPopup />
        </Popup>
      );
    }
  }

  faqPopup() {
    if (this.props.popup.type_Faq_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_Faq_IsVisible}
          onCloseRequested={this.closeFaqPopup}
          id="faq-popup-modal"
        >
          <FaqPopup />
        </Popup>
      );
    }
  }

  bookingEditPopup() {
    if (this.props.popup.type_BookingEdit_IsVisible) {
      return (
        <Popup
          isOpen
          onCloseRequested={this.closeBookingEditPopup}
          buttonPositionStatic
          id="bookingEditPopup"
        >
          <BookingEditPopup />
        </Popup>
      );
    }
  }

  creditCardPopup() {
    if (this.props.popup.type_CreditCard_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_CreditCard_IsVisible}
          onCloseRequested={_partial(this.props.popup_CreditCard_SetVisibility, false)}
          id="creditCard-popup"
        >
          <CreditCardPopup
            handleSubmit={this.handleGetCreditCard}
            handleCancel={this.closeCreditCardPopup}
          />
        </Popup>
      );
    }
  }

  confirmAnonymPopup() {
    if (this.props.popup.type_AnonymConfirm_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_AnonymConfirm_IsVisible}
          onCloseRequested={this.closeAnonymizeConfirmPopup}
          id="anonymConfirm-popup"
        >
          <AnonymizeConfirmPopup handleSubmit={this.handleAnonymizeConfirmPopup}>
            <FormattedMessage id="anonymize.confirm.description" />
          </AnonymizeConfirmPopup>
        </Popup>
      );
    }
  }

  paymentErrorPopup() {
    return <PaymentErrorPopup />;
  }

  genericErrorPopup() {
    if (this.props.popup.type_Generic_IsVisible) {
      return (
        <Popup
          isError
          isOpen={this.props.popup.type_Generic_IsVisible}
          onCloseRequested={this.hideGenericPopupAction}
          id="generic-error-popup"
        >
          <GenericPopup />
        </Popup>
      );
    }
  }

  activationPopup() {
    if (this.props.popup.type_AccountValidation_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_AccountValidation_IsVisible}
          onCloseRequested={_partial(
            this.props.popup_AccountValidation_SetVisibility,
            false
          )}
          id="account-validation-confirmation-popup"
        >
          {this.renderActivationPopup()}
        </Popup>
      );
    }
  }

  activationEmailSentPopup() {
    if (this.props.popup.type_ActivationEmailSent_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_ActivationEmailSent_IsVisible}
          onCloseRequested={_partial(
            this.props.popup_ActivationEmailSent_SetVisibility,
            false
          )}
          id="activation-email-sent-popup-css"
        >
          {this.renderActivationEmailSentPopup()}
        </Popup>
      );
    }
  }

  recapPopup() {
    if (this.props.popup.type_BookingRecap_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_BookingRecap_IsVisible}
          onCloseRequested={this.closeRecapPopup}
          dark={true}
          id="recap-popup"
          outerClose={true}
          backdropClass="recap-booking"
        >
          <BookingRecapPopup />
        </Popup>
      );
    }
  }

  resetPasswordPopup() {
    if (this.props.popup.type_ResetPassword_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_ResetPassword_IsVisible}
          onCloseRequested={_partial(this.props.popup_ResetPassword_SetVisibility, false)}
          id="reset-password-popup"
        >
          <div>
            <div className="description">
              {this.props.user.resetPasswordError ? (
                this.props.user.resetPasswordError
              ) : (
                <FormattedMessage id="label.reset_password_done" />
              )}
            </div>
          </div>
        </Popup>
      );
    }
  }

  bookingCancelPopup() {
    if (this.props.popup.type_BookingCancel_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_BookingCancel_IsVisible}
          onCloseRequested={_partial(this.closeBookingCancelPopup)}
          id="booking-cancel-popup"
        >
          <BookingCancelPopup />
        </Popup>
      );
    }
  }

  bookingSuccessPopup() {
    if (this.props.popup.type_BookingSuccess_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_BookingSuccess_IsVisible}
          onCloseRequested={this.closeBookingSuccessPopup}
          id="booking-success-popup"
        >
          <BookingSuccessPopup />
        </Popup>
      );
    }
  }

  renderPopups() {
    return (
      <div>
        {this.bookingEditPopup()}
        {this.loginPopup()}
        {this.faqPopup()}
        {this.creditCardPopup()}
        {this.paymentErrorPopup()}
        {this.genericErrorPopup()}
        {this.activationPopup()}
        {this.activationEmailSentPopup()}
        {this.recapPopup()}
        {this.resetPasswordPopup()}
        {this.bookingCancelPopup()}
        {this.bookingSuccessPopup()}
        {this.confirmAnonymPopup()}
      </div>
    );
  }

  getHowItWorks() {
    if (isHomePage()) return <HowItWorks />;
  }

  getIntlLocale() {
    const { locale } = this.props.i18n;
    return pt_BR_map[locale] || locale;
  }

  showLoader() {
    return (
      <div className="app-loader">
        <div className="sk-chase">
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
        </div>
      </div>
    );
  }

  displayMainApp() {
    return (
      <IntlProvider
        key={this.props.i18n.locale}
        messages={this.props.i18n.messages}
        locale={this.getIntlLocale()}
      >
        <IntlGlobalProvider>
          <div className={classnames('site-container', this.getPageClass())}>
            {this.renderPopups()}
            {this.getHeaderBanner()}

            <div className="main-background" style={this.renderBackgroundProps()}>
              {this.getMobileMenu()}
              {this.getHeader()}
              <main>
                <Outlet />
              </main>
            </div>

            {this.getHowItWorks()}
            {this.getFooter()}
            {this.setStyle()}
            {this.setMobileStyle()}
          </div>
        </IntlGlobalProvider>
      </IntlProvider>
    );
  }

  render() {
    const { i18n, brand, showAppLoader } = this.props;
    const { configFetched } = brand;
    const { messages } = i18n;

    return (
      <div className={classnames('root-container', { 'app-loading': showAppLoader })}>
        {showAppLoader && this.showLoader()}
        {messages && configFetched && this.displayMainApp()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
    m: messagesSelector(state),
    showAppLoader: showAppLoaderSelector(state),
    userCompanyId: userCompanyIdSelector(state),
    searchValues: searchValuesSelector(state),
    keycloakConfig: keycloakConfigSelector(state),
    brandKeycloakSelector: brandKeycloakSelector(state),
    isAppliedUser: isAppliedUserSelector(state),
    isIncompleteUser: isIncompleteUserSelector(state),
    userId: userIdSelector(state),
    theme: currentThemeSelector(state),
    isPrimaryColorDarkContrast: isPrimaryColorDarkSelector(state),
    isSecondaryColorDarkContrast: isSecondaryColorDarkSelector(state),
    userImpersonated: userImpersonatedSelector(state),
    memberInKeycloak: memberInKeycloakSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...BookingActions,
      ...CreditCardActions,
      ...UserActions,
      ...PopupActions,
      ...i18nActions,
      ...BrandActions,
      ...KeycloakActions,
      ...ImpersonateActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
