import { PAYMENT_PROVIDER } from '../constants/backend';

// noinspection JSUnusedGlobalSymbols
export function getCreditCardURL(returnUrl) {
  return (dispatch) => {
    return dispatch({
      type: 'card/GET_URL',
      returnUrl,
      payload: {
        request: {
          method: 'get',
          url: '/v2/members/payment/url'
        }
      }
    });
  };
}

export function createCardWorldpay(memberId, orderCode) {
  return (dispatch) => {
    return dispatch({
      type: 'worldpay/card/CREATE',
      payload: {
        request: {
          method: 'put',
          url: '/v2/members/' + memberId + '/payment',
          data: {
            orderCode: orderCode
          }
        }
      }
    });
  };
}

// noinspection JSUnusedGlobalSymbols
export function createCreditCard(memberId, orderCode) {
  return (dispatch) => {
    return dispatch(createCardWorldpay(memberId, orderCode));
  };
}

export function getStripeCard() {
  return (dispatch) =>
    dispatch({
      type: 'stripe/card/GET',
      payload: {
        request: {
          method: 'get',
          url: '/v2/member/credit-cards'
        }
      }
    });
}

export function getWorldpayCard(id) {
  return (dispatch) =>
    dispatch({
      type: 'worldpay/card/GET',
      payload: {
        request: {
          method: 'get',
          url: '/v2/members/' + id + '/payment'
        }
      }
    });
}

// noinspection JSUnusedGlobalSymbols
export function getCreditCardInfo(id) {
  return (dispatch, getState) => {
    const provider = getState().user?.data?.company?.paymentProvider;
    const action =
      provider === PAYMENT_PROVIDER.STRIPE ? getStripeCard() : getWorldpayCard(id);
    return dispatch(action);
  };
}

export function setupIntentStripe() {
  return (dispatch) =>
    dispatch({
      type: 'stripe/card/CREATE',
      payload: {
        request: {
          method: 'post',
          url: '/v2/member/credit-cards/stripe'
        }
      }
    });
}

// noinspection JSUnusedGlobalSymbols
export function deleteCreditCard(memberProfileId) {
  return (dispatch, getState) => {
    const {
      user: {
        data: {
          company: { paymentProvider }
        }
      }
    } = getState();
    const req =
      paymentProvider === PAYMENT_PROVIDER.STRIPE
        ? deleteStripeCard()
        : deleteWorldpayCard(memberProfileId);

    return dispatch(req);
  };
}

export function deleteStripeCard() {
  return (dispatch) =>
    dispatch({
      type: 'stripe/card/DELETE',
      payload: {
        request: {
          method: 'delete',
          url: '/v2/member/credit-cards'
        }
      }
    });
}
export function deleteWorldpayCard(memberProfileId) {
  return (dispatch) =>
    dispatch({
      type: 'worldpay/card/DELETE',
      payload: {
        request: {
          method: 'delete',
          url: '/v2/members/' + memberProfileId + '/payment'
        }
      }
    });
}
