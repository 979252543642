import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';

import { FormattedMessage } from 'react-intl';
import mobileImg from '../../assets/images/mobile-app-rf.png';

import arrowLeftSrc from '../../assets/images/arrow-left.svg';
import corporate from '../../assets/images/usage-types/corporate.svg';
import vr from '../../assets/images/tools-circle.svg';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../actions/popup';
import * as UserActions from '../../actions/user';

import { connect } from 'react-redux';
import { links } from '../../constants/links';
import appStoreImg from '../../assets/images/app-store.png';
import googlePlayImg from '../../assets/images/google-play.png';
import InviteCodeForm from './InviteCodeForm';

import {
  brandIdSelector,
  isSsoOnlyResp,
  productNameSelector,
  profileCompanyIdsSelector,
  profilePrivateRentalAllowedSelector
} from '../../helpers/selectors';

import { navigateToCreationPage } from '../../utils/utils';

class SubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initSteps();
    this.initState();
  }

  initSteps() {
    this.STEP = {
      SERVICE_CHOICE: () => this.renderStepServiceChoice(),
      MOBILE_APPS: () => this.renderStepMobileAppLinks(),
      CORPORATE: () => this.renderStepCorporate(),
      VR: () => this.renderStepVR()
    };
  }

  setCurrentStep(step) {
    this.setState({ currentStep: step });
  }

  getCurrentStep() {
    return this.state.currentStep();
  }

  initState() {
    this.state = { currentStep: this.STEP.SERVICE_CHOICE };
  }

  setCallbacks() {
    this.vrAction = () => {
      const { directSubscribe, closeAllPopups, closeModal } = this.props;
      return directSubscribe ? closeAllPopups() : closeModal();
    };

    this.handleInviteSubmit = (data) => {
      this.props.checkInviteCode(data.inviteCode, true).then((data) => {
        if (data.type === 'user/CHECK_INVITE_CODE_SUCCESS') {
          const companyId = data.payload.data.id;

          this.props.getCompany(companyId).then((resp) => {
            const id = this.props.brandId;

            if (resp.payload.data.brand.id !== id) {
              this.props.addInvitError({ errorCode: 'member.profile.different.brand' });
            } else if (
              resp.payload.data.brand.id === id &&
              this.props.profileCompanyIds &&
              this.props.profileCompanyIds.some((id) => id === companyId)
            ) {
              this.props.addInvitError({
                errorCode: 'info.account.company.already.linked'
              });
            } else if (isSsoOnlyResp(resp)) {
              this.props.addInvitError({
                errorCode: 'error.register.without.sso'
              });
            } else {
              this.props.closeAllPopups();
              navigateToCreationPage(companyId);
            }
            this.props.checkInviteCodeStopFetch();
          });
        }
      });
    };
  }

  componentWillUnmount() {
    this.props.resetSubscribeDisplay();
  }

  componentDidMount() {
    if (this.props.user.serviceRv) {
      this.setCurrentStep(this.STEP.VR);
    }
    if (this.props.user.serviceB2b) {
      this.setCurrentStep(this.STEP.CORPORATE);
    }
    this.handleRenaultFranceUser();
  }

  handleRenaultFranceUser() {
    const { showRenaultFrancePopup } = this.props.user;

    if (showRenaultFrancePopup) {
      this.setCurrentStep(this.STEP.MOBILE_APPS);
      this.props.setFlagToOpenRenaultFrancePopup(false);
    }
  }

  renderCorporateRentalChoice() {
    return (
      <div
        className="corporate"
        onClick={() => this.setCurrentStep(this.STEP.CORPORATE)}
      >
        <FormattedMessage id="service.usage.corporate" />
      </div>
    );
  }

  renderPrivateRentalChoice() {
    const { popup, profilePrivateRentalAllowed } = this.props;
    if (popup.step_AddAccount_IsVisible && !profilePrivateRentalAllowed) return;

    return (
      <div
        className="private"
        onClick={() => this.setCurrentStep(this.STEP.MOBILE_APPS)}
      >
        <FormattedMessage id="service.usage.private" />
      </div>
    );
  }

  renderVrRentalChoice() {
    const { popup } = this.props;

    if (!popup.step_AddAccount_IsVisible) {
      return (
        <div className="vr" onClick={() => this.setCurrentStep(this.STEP.VR)}>
          <FormattedMessage id="service.usage.vr" />
        </div>
      );
    }
  }

  renderStepServiceChoice() {
    const { productName = 'Car sharing' } = this.props.brand.branded || {};

    return (
      <div>
        <h3>
          <FormattedMessage id="service.intro_label" values={{ productName }} />
        </h3>
        <div className="offers">
          {this.renderCorporateRentalChoice()}
          {this.renderPrivateRentalChoice()}
          {this.renderVrRentalChoice()}
        </div>
      </div>
    );
  }

  renderStepCorporate() {
    const {
      directSubscribe,
      brand: { branded }
    } = this.props;

    const { corporate: corpIconUrl } = branded.servicesIcons || {};

    return (
      <div className="wrapper">
        <h2>
          {!directSubscribe && (
            <span onClick={() => this.setCurrentStep(this.STEP.SERVICE_CHOICE)}>
              <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
            </span>
          )}
          <FormattedMessage id="service.usage.corporate" />
          {this.closeBtn()}
        </h2>
        <div className="text-center">
          <ReactSVG className="react-svg" src={corpIconUrl ? corpIconUrl : corporate} />
        </div>
        <div className="text-center">
          <FormattedMessage id="service.corporate.intro" />
        </div>
        <InviteCodeForm
          onSubmit={this.handleInviteSubmit}
          onRetry={this.props.removeInviteError}
          isInPopup={true}
        />
      </div>
    );
  }

  renderStepMobileAppLinks() {
    const { productName } = this.props;

    return (
      <div className="wrapper">
        <h2>
          <span onClick={() => this.setCurrentStep(this.STEP.SERVICE_CHOICE)}>
            <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
          </span>
          <span>{productName}</span>
        </h2>
        <div className="text-center">
          <img src={mobileImg} alt="Renault Mobility France" />
        </div>
        <div className="text-center padd-text">
          <FormattedMessage id="service.private.renaultfr" values={{ productName }} />
        </div>

        <div className="text-center imgs">
          <a href={links.appStoreLink}>
            <img
              src={appStoreImg}
              alt="App Store"
              className="mobile-store-img mr-5 va-top"
            />
          </a>
          <a href={links.playStoreLink}>
            <img
              src={googlePlayImg}
              alt="Google Play"
              className="mobile-store-img ml-5 va-top mb-xs-10"
            />
          </a>
        </div>
      </div>
    );
  }

  renderStepVR() {
    const {
      directSubscribe,
      brand: { branded }
    } = this.props;

    const { vr: vrIconUrl } = branded.servicesIcons || {};

    return (
      <div className="wrapper">
        <h2>
          {!directSubscribe && (
            <span onClick={() => this.setCurrentStep(this.STEP.SERVICE_CHOICE)}>
              <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
            </span>
          )}
          <FormattedMessage id="service.usage.vr" />
          {this.closeBtn()}
        </h2>

        <div className="text-center">
          <ReactSVG className="react-svg" src={vrIconUrl ? vrIconUrl : vr} />
        </div>
        <div className="text-center ws">
          <FormattedMessage id="service.vr.intro" />
        </div>

        <div className="text-center">
          <button className="btn btn-md btn-accent primary-btn" onClick={this.vrAction}>
            ok
          </button>
        </div>
      </div>
    );
  }

  closeBtn() {
    const { directSubscribe, closeAllPopups } = this.props;

    if (directSubscribe)
      return (
        <div className="close-button-wrap">
          <button type="button" onClick={closeAllPopups}>
            <i className="icon-cross" />
          </button>
        </div>
      );
  }

  render() {
    return <div className="subscription">{this.getCurrentStep()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    ...state,
    directSubscribe: state.popup.directSubscribe,
    profileCompanyIds: profileCompanyIdsSelector(state),
    profilePrivateRentalAllowed: profilePrivateRentalAllowedSelector(state),
    brandId: brandIdSelector(state),
    productName: productNameSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PopupActions, ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeForm);
