import { reduxForm } from 'redux-form';
import React from 'react';
import { fallbackFunc, getMessage as t } from '../../../utils/utils';
import {
  checkEmail,
  checkPassword,
  checkPhoneNumberFull,
  checkText
} from '../../../helpers';
import { connect } from 'react-redux';
import { brandIdSelector, messagesSelector } from '../../../helpers/selectors';
import PropTypes from 'prop-types';
import { USER_FORM_NAMES } from '../../../constants/form';
import PhoneNumberField from '../../account/fields/phoneNumber';
import TextField from '../../forms/newFields/text';
import classnames from 'classnames';
import PasswordField from '../../forms/newFields/password';

const validate = (v, p) => {
  const errors = {};
  const textFields = ['firstName', 'lastName'];

  textFields.forEach((name) => {
    errors[name] = checkText(v[name], p.m);
  });

  errors.login = checkEmail(v.login, p.m);
  errors.password = checkPassword(v.password, p.m);
  errors.phoneNumber = checkPhoneNumberFull(v.phoneNumber, p.m);

  return errors;
};

function addLabels(m, id) {
  const text = t(m, id);
  return {
    label: text,
    placeholder: text
  };
}

function CreationCredentialsForm(p) {
  return (
    <form
      className={classnames('account-credentials-form', 'scope-section')}
      onSubmit={p.handleSubmit}
      noValidate
    >
      <div className="scope-section-fields">
        <TextField name="firstName" {...addLabels(p.m, 'generic.first.name')} />
        <TextField name="lastName" {...addLabels(p.m, 'generic.last.name')} />
        <PhoneNumberField
          name="phoneNumber"
          label={t(p.m, 'generic.mobile.phone')}
          mandatory
        />
        <TextField
          name="login"
          autoComplete="email"
          {...addLabels(p.m, 'generic.email')}
        />
        <PasswordField
          name="password"
          autoComplete="new-password"
          {...addLabels(p.m, 'label.password')}
        />
      </div>
    </form>
  );
}

CreationCredentialsForm = reduxForm({
  form: USER_FORM_NAMES.REGISTER_CREDENTIALS,
  fields: ['firstName', 'lastName', 'phoneNumber', 'login', 'password'],
  asyncBlurFields: ['login'],
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
})(CreationCredentialsForm);

function mapStateToProps(state) {
  return {
    m: messagesSelector(state),
    brandId: brandIdSelector(state)
  };
}

CreationCredentialsForm = connect(mapStateToProps)(CreationCredentialsForm);

CreationCredentialsForm.defaultProps = {
  onSubmit: fallbackFunc
};

CreationCredentialsForm.propTypes = {
  onSubmit: PropTypes.func
};

export default CreationCredentialsForm;
